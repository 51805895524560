import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import './volunteer.css'

function Volunteer() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Volunteer</title>
      </Helmet>
      <div className='volunteer-hero'></div>

      <br />
      <motion.div
        initial={{ scale: 0.65 }}
        whileInView={{ scale: 0.99 }}
        transition={{ ease: 'easeOut', duration: 1 }}
        className='volunteer'
      >
        <h1>Volunteering</h1>
        <p>
          Dear friends of Ghetto Kids, <br />
          <br />
          We appreciate your interest in volunteering with our organization.
        </p>
        <p>
          We understand that many people are passionate about supporting our
          cause and giving back to the community through volunteering. We are
          grateful for the overwhelming response we have received from
          individuals who want to contribute their time and effort to our
          organization.
        </p>
        <p>
          However, we regret to inform you that all volunteering positions are
          currently full, and we are not accepting any new applications at this
          time. However, due to the high demand for volunteering positions, we
          have reached our capacity and are unable to accommodate any additional
          volunteers at this time. We want to ensure that our current volunteers
          receive the best possible experience and support from us, and to
          achieve that, we need to maintain a manageable number of volunteers.
        </p>
        <p>
          We encourage you to check back on our website periodically, as we may
          have new volunteering opportunities in the future. In the meantime,
          please continue to support us by spreading the word about our
          organization, donating to our cause, and engaging with us on social
          media.
        </p>
        <p>
          We appreciate your understanding and thank you for your interest in
          volunteering with us. <br />
          <br />
          Sincerely, <br />
          Dauda Kavuma
        </p>
      </motion.div>
      <br />
      <br />
      <br />
    </>
  )
}

export default Volunteer

import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import './founder.css'
import dauda from '../../assets/dauda.jpg'

function Founder() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Founder</title>
      </Helmet>
      <div className='founder-hero'></div>
      <motion.div
        initial={{ scale: 0.65 }}
        whileInView={{ scale: 0.99 }}
        transition={{ ease: 'easeOut', duration: 1 }}
        className='founder'
      >
        <div className='founder-img'>
          <img src={dauda} alt='dauda kavuma' />
          <p>- Dauda Kavuma -</p>
        </div>
        <br />
        <p>
          After completing my graduation as a mathematics teacher in 2007, I
          realized that my responsibility towards my students extended beyond
          the four walls of the classroom. As someone who had experienced life
          as a street kid, I felt a deep sense of empathy towards those boys and
          girls who were going through similar struggles. I was determined to be
          a positive influence in their lives, just as someone had been for me.
        </p>
        <br />
        <p>
          I was born in 1979 in Butambala during a turbulent period in Uganda's
          history. The aftermath of a war with Tanzania had left the economy in
          shambles and claimed the lives of countless people. Unfortunately, the
          end of that war did not bring peace. For the first six years of my
          life, Uganda was embroiled in a devastating civil war that wreaked
          havoc on the lives and livelihoods of many, leaving the country in
          ruins. Despite surviving this tumultuous period, I was not without my
          own hardships. My father had passed away, leaving my mother to provide
          for our family on her own. Consequently, I had to learn how to hustle
          from a very young age, just like many other kids and adults in our
          community. Hustling involved finding ways to earn money, which might
          entail rummaging through garbage heaps to collect scrap or selling
          fruit to drivers at stoplights. In some instances, people would even
          perform in the streets for donations. Although hustling taught me
          invaluable lessons and made me streetwise, it was also perilous. I
          learned to recognize danger and quickly identify potential escape
          routes.
        </p>
        <br />
        <p>
          Surviving poverty was like playing a strategic game where the stakes
          were high, and defeat meant facing death. Many children like me had to
          confront danger on a daily basis. Unfortunately, not everyone
          survived, but for those of us who did, the experience taught us
          valuable lessons. We learned to never give up without a fight and to
          expect that life would not be easy. In Uganda, the term "ghetto"
          encompasses a broad spectrum of experiences that vary by generation
          and level of difficulty. The shanty towns were remarkable examples of
          resourcefulness and innovation. Basic necessities like electricity and
          clean water were luxuries that we could not afford.
        </p>
        <br />
        <p>
          I came to the realization that education is not solely about attending
          school, but it is about being open and prepared to learn. Despite my
          inability to afford even the most affordable school fees, my daily
          experiences in the ghetto were teaching me valuable lessons. Hope was
          not some abstract notion but a tangible concept that revealed itself
          in practical ways. My innate curiosity compelled me to pose questions
          whenever I had the opportunity. As I grew, I became more adept at
          asking the right questions that elicited informative answers. I also
          learned to reflect on what had worked for me in the past and what
          hadn't, in an effort to minimize negative outcomes. My friends and I
          would collaborate and strategize, experimenting with new ideas and
          providing support to one another when things did not go as planned.
          Even now, I still observe the same entrepreneurial spirit among street
          kids.
        </p>
        <br />
      </motion.div>
    </>
  )
}

export default Founder

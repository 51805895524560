import styled from 'styled-components'

// button with transparent bckgrd
export const ButtonW = styled.button`
  border-radius: 40px;
  margin: 0.4rem;
  padding: 0.6rem 1rem;
  width: 100px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  &:hover {
    background-color: teal;
    border: 1px solid teal;
    cursor: pointer;
  }
`
// button with teal bckgrd
export const ButtonT = styled.button`
  border-radius: 40px;
  margin: 0.4rem;
  padding: 0.6rem 1rem;
  width: 120px;
  color: #fff;
  border: 1px solid teal;
  background-color: teal;
  &:hover {
    cursor: pointer;
    background-color: black;
    border: 1px solid black;
  }
`
export const Heading = styled.h3`
  text-align: center;
`

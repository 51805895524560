const IMAGES = [
  {
    id: 1,
    title: "Performing at Britain's Got Talent",
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/11azIEBQXGnXYJfTMtDugd/bf4b2e131113fdd6b2d9ad40a0c82960/BGT23.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 2,
    title: 'Education Matters',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/53HCWrRkbNziXg8jfvSOlG/25a57723b35f5f5046e28c3e84640ce8/image00001.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 3,
    title: 'snowflake3',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/32i6Ftw8f3bjCRsl54J2IV/b58dabcd7ec19794f1579603854a0394/image00019.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 4,
    title: 'snowflake4',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/5yXF8ZW71zHavNFHFZDL2Y/aa081168df8001b5a7bc35b3cfea8a0e/kid650.jpg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 5,
    title: 'snowflake5',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/3gvfbLnbgb1r1Tnkv7wzm3/edc51d72ad698f4254693c5f75630ccd/image00002.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 6,
    title: 'snowflake6',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/4Jp3vkt04fxvNl2uwrWfLB/eb08d527660fec02e42e7e193ed09ebb/image00017.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 7,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/5uQvesgapoOG2sITWeN6hN/852b992d690c3d3b150588028bb18a33/family.jpg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 8,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/6jnVgxYE8a8NmXWgZld90r/31724eacce12132431566ddc48563dc6/playtime.jpg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 9,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/5Lw7EfIettgMCDB4ACuVvA/efceec0eed398c5f7c8d576b354a3b14/chill.jpg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 10,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/32c1jceMDmEdwkwP9icSmD/bbef0bea617e05e46737e1a7e18a3856/image00008.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },

  {
    id: 11,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/64RXsl55nW3LaIIQpSc8pa/324ce5d3e9b46e6809290c99ac633ece/image00012.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
  {
    id: 12,
    title: 'snowflake7',
    desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi beatae, quasi ducimus recusandae nobis labore vero. Officiis quod reiciendis sed!',
    url: 'https://images.ctfassets.net/wlgvz2z255oc/4R4WY9iKWIyuIZsmrmiNy6/82ac07794aa06da66f6fcac9de0af8aa/image00010.jpeg?w=1000&h=1500&fm=jpg&fl=progressive',
  },
]

export default IMAGES

import styled from 'styled-components'
import logomob from '../../assets/logo.png'

import { Link } from 'react-router-dom'

const BigLogo = styled.div`
  padding-top: 2.3rem;
  padding-left: 2.5rem;
  width: 6rem;
  height: 6rem;
  position: absolute;
  z-index: 3;
  outline: none;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`
const SmallLogo = styled.div`
  width: 6rem;
  height: 6rem;
  padding-top: 2rem;
  padding-left: 2rem;
  position: absolute;
  z-index: 3;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`
export default function Logo() {
  return (
    <>
      <BigLogo>
        <Link to='/'>
          <img
            src={logomob}
            alt='apercu logo'
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Link>
      </BigLogo>
      <SmallLogo>
        <Link to='/'>
          <img
            src={logomob}
            alt='logo'
            style={{
              width: '100%',
              height: '100%',
              marginTop: '8px',
              padding: '0px',
            }}
          />
        </Link>
      </SmallLogo>
    </>
  )
}

import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import '../Volunteer/volunteer.css'

function Thanks() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Thank you</title>
      </Helmet>
      <div className='volunteer-hero'></div>
      <br />
      <motion.div
        initial={{ scale: 0.65 }}
        whileInView={{ scale: 0.99 }}
        transition={{ ease: 'easeOut', duration: 1 }}
        className='volunteer'
      >
        <h1>Received, Thank you!</h1>

        <p>
          I just wanted to take a moment to thank you for taking the time to
          send a message through our website. Your feedback and inquiries are
          greatly appreciated and help us to improve our services.
        </p>
        <p>
          I am pleased to inform you that your message has been successfully
          sent and our team will get back to you as soon as possible.
        </p>
        <p>
          Please allow us some time to review your message and respond
          accordingly.
        </p>
        <p>
          Thank you again for your interest in what we do and we look forward to
          connecting with you soon.
        </p>
        <p>Sincerely, </p>
        <p>Ghetto Kids&#128516;</p>
      </motion.div>
      <br />
      <br />
      <br />
      <div style={{ height: '400px' }}></div>
    </>
  )
}

export default Thanks

import '../Volunteer/volunteer.css'
import { Helmet } from 'react-helmet'

function Work() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Work</title>
      </Helmet>
      <div className='volunteer-hero'></div>
      <br />

      <div className='volunteer'>
        <h1>What we do</h1>

        <p>
          <strong>Inspire Ghetto Kids Foundation</strong> currently has over
          thirty kids from less previleged backgrounds and families. There
          hasbeen alot of trials and hurdles in the last sixteen years of growth
          as a foundation, We feel excited about any challenge that comes across
          our way because such barriers have helped us morph into a resilient
          team of harding working, sweet and polite people. We attribute the
          endless motivation we gain from the kids through their smiles, dances,
          educational progress, solace from their troubled pasts and general
          transformation into lovely beings.
        </p>
        <p>
          Providing a solid hope for the orphaned, vulnerable, deprived and
          destitute children seeking a second chance to life. The approach of
          rehabilitation is family oriented. This is achieved through provision
          of education, healthcare, shelter, mentorship, clothing and other
          relatived basic needs.
        </p>
        <p>
          Have we won the war towards improving the lives of the disadvantaged
          kids in our communities? We are from that honestly. The foundation
          survives on the bookings from our performing kids, donations from
          organizations and generous beings like you. On that note we humbly
          request for any form of donation or sponsorship that would help us
          further better the lives of the kids at the foundation and stretch out
          to more kids seeking a better livelihood in our communities.
        </p>
      </div>
      <br />
      <br />
      <br />
    </>
  )
}

export default Work

import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'
import './donate.css'
function Donate() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Donate</title>
      </Helmet>
      <div className='donate'>
        <div className='donate-hero'></div>
        <br />

        <motion.div
          initial={{ scale: 0.65 }}
          whileInView={{ scale: 0.99 }}
          transition={{ ease: 'easeOut', duration: 1 }}
          className='donate-main'
        >
          <h1 style={{ border: 'none' }}>
            <strong>Donate Now Change A Life</strong>
          </h1>
          <br />
          <p>
            <strong>Thank you for choosing to donate</strong>
          </p>
          <h1>Ways to make a donation</h1>
          <br />
          <p>
            When you donate to the foundation, you help us provide food,
            clothing, medical care, education and a safe place they can call
            home. Whatever your donation, large or small, it provides important
            resource and help as our family grows and we look to help more
            disadvantaged, vulnerable or orphaned children in Uganda.
          </p>
          <h1>International bank transfer</h1>
          <br />
          <p>
            <strong>Name of Bank:</strong>
            &nbsp;&nbsp;Absa Bank (U) Ltd
          </p>
          <br />
          <p>
            <strong>Bank Address:</strong>
            &nbsp;&nbsp;Plot 2/4 Hannington Road Kampala, Uganda
          </p>
          <br />
          <p>
            <strong>Swift code:</strong>
            &nbsp;&nbsp;BARCUGKX
          </p>
          <br />
          <p>
            <strong>Account number:</strong>
            &nbsp;&nbsp;6005603259
          </p>
          <br />
          <p>
            <strong>Account holder name:</strong>
            &nbsp;&nbsp;Inspire Triplets Ghetto Kids
          </p>
          <br />
          <p>
            <strong>Address of account holder:</strong>
            &nbsp;&nbsp;Salama rd - Kalani Zone, POBOX 21347 Kampala Makindye –
            Kampala, Uganda
          </p>
          <h1>Western Union:</h1>
          <br />
          <p>Kavuma Dauda</p>
          <h1>Chipper Cash:</h1>
          <br />
          <p>Kavuma Dauda</p>
          <h1>Cash | Cheque</h1>
          <br />
          <p>
            Make your cash or cheque donations at our Inspire ghetto kids office
            along Salama Road,Makindye - Kampala during office hours (Monday to
            Sunday; 8:00 am to 5:00pm).
          </p>
          <p>Be sure to get a receipt for your cash donation.</p>
          <h1>Mobile Mobile</h1>
          <br />
          <p>Make a direct deposit or send to:</p>
          <p style={{ color: 'var(--main)' }}> (Mtn) +256771850668 </p>
          <p style={{ color: 'var(--main)' }}> (Airtel) +256704877551.</p>
          <p>
            We would appreciate it alot if you added a reason to your
            transaction.
          </p>
          <h1>Postal donations</h1>
          <br />
          <p>
            If you wish to donate items such as clothing, school material or
            toys we accept packages at our address:
          </p>
          <p> Triplets Ghetto Kids</p>
          <p>Makindye Division – Kampala</p>
          <p> Salama rd - Kalani Zone P O box 21347, Kampala Uganda</p>
          <br />
          <p> We thank you all for your generosity and kind hearts.</p>
        </motion.div>
      </div>
    </>
  )
}

export default Donate

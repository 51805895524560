import Hero from '../components/Hero/Hero'
import Mission from '../components/Mission/Mission'
import Feature from './Feature/Feature'
// import MSlider from './MSlider'
import Slider from './Slider'

function Home() {
  return (
    <>
      <Hero />
      <Mission />
      <Feature />
      <Slider />
      {/* <MSlider /> */}
    </>
  )
}

export default Home

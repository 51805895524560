import './footer.css'
import logo from '../../assets/logo.png'

function Footer() {
  return (
    <div className='footer-wrap'>
      <div className='footer'>
        <div className='footer-logo'>
          <a href='https://inspireghettokids.org/' className='logo'>
            <img src={logo} alt='ghetto kids' className='logo' />
          </a>
        </div>

        <div className='footer-contact'>
          <p id='footer-bold'>Contact</p>
          <p id='footer-normal'>
            <a href='mailto:info@inspireghettokids.com'>Email Ghetto kids</a>
          </p>

          <p id='footer-normal'>
            <a href='tel:+256 771 850 668'>+256 771 850 668</a>
          </p>
        </div>
        <div className='footer-follow'>
          <p id='footer-bold'>Follow us</p>
          <p id='footer-normal'>
            <a
              href='https://www.youtube.com/c/TripletsGhettoKids'
              target='_blank'
              rel='noopener noreferrer'
            >
              YouTube
            </a>
          </p>
          <p id='footer-normal'>
            <a
              href='https://www.instagram.com/ghettokids_tfug'
              target='_blank'
              rel='noopener noreferrer'
            >
              Instagram
            </a>
          </p>
          <p id='footer-normal'>
            <a
              href='https://www.facebook.com/TripletsGhettoKidz'
              target='_blank'
              rel='noopener noreferrer'
            >
              Facebook
            </a>
          </p>
        </div>
        <div className='footer-operating'>
          <p id='footer-bold'>Operating Hours</p>
          <p id='footer-normal'>8AM to 7PM EAT</p>
          <p id='footer-normal'>Monday to Sunday</p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <p style={{ color: 'var(--muted)', textAlign: 'center' }}>
        &copy;
        {new Date().getFullYear()}&nbsp;Inspire Ghetto Kids Foundation.
      </p>
    </div>
  )
}

export default Footer

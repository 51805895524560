import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import styled from 'styled-components'

import IMAGES from './data'

function Slider() {
  return (
    <>
      <Wrapper>
        <Splide
          options={{
            perPage: 3,
            gap: 5,
            drag: 'free',
            arrows: false,
            pagination: false,

            breakpoints: {
              650: {
                perPage: 1,
              },
            },
          }}
        >
          {IMAGES &&
            IMAGES.map((item) => {
              return (
                <SplideSlide key={item.id}>
                  <Card>
                    <img src={item.url} alt='kids' />
                    <p> </p>
                  </Card>
                </SplideSlide>
              )
            })}
        </Splide>
      </Wrapper>
    </>
  )
}

export default Slider

const Wrapper = styled.div`
  margin: auto;
  margin-bottom: 2rem;
  width: 90%;

  @media and (max-width: 650px) {
    width: 95%;
  }
`
const Card = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  height: 20rem;
  width: 100%;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    left: 0;
    object-fit: cover;
  }
  p {
    position: absolute;
    color: var(--wht);
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0%);

    width: 100%;
    text-align: center;
    font-size: 1rem;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  }
`

import { Routes, Route } from 'react-router-dom'
import './App.css'
import Donate from './components/Donate/Donate'
import Footer from './components/Footer/Footer'
import Volunteer from './components/Volunteer/Volunteer'
import Home from './components/Home'
import Burger from './components/Nav/Burger'
import Logo from './components/Nav/Logo'
import Work from './components/Work/Work'
import Contact from './components/Contact/Contact'
import Founder from './components/Founder/Founder'
import Thanks from './components/Contact/Thanks'

function App() {
  return (
    <>
      <Logo />
      <Burger />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='/volunteer' element={<Volunteer />} />
        <Route path='/work' element={<Work />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/thankyou' element={<Thanks />} />
        <Route path='/founder' element={<Founder />} />
      </Routes>

      <Footer />
    </>
  )
}

export default App

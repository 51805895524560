import { motion } from 'framer-motion'

import vibe from '../../assets/vibe.png'
import uni from '../../assets/uni.png'
import bet from '../../assets/bet.png'
import fox from '../../assets/fox.png'
import jimmy from '../../assets/jimmy.png'
import influ from '../../assets/100.png'
import disney from '../../assets/disney.png'
import talent from '../../assets/talent.png'

import './feature.css'

function Feature() {
  return (
    <>
      <motion.div
        initial={{ scale: 0.65 }}
        whileInView={{ scale: 0.99 }}
        transition={{ ease: 'easeOut', duration: 2 }}
        className='feature-main'
      >
        <p>
          <strong>Ghetto kids</strong> represents the dreams, aspirations and
          desires of the so many children living in the ghetto, streets and
          other unsafe places lacking the basic needs of life.Through our mainly
          musical projects we help re-assure the rest that there's still hope
          for every child out there in dare need of day today basic necessities.
        </p>
        <p>
          In 2014, Triplets Ghetto kids gained massive media interest after the
          home made dance video of "sitya loss" by{' '}
          <a
            href='https://www.eddykenzo.com/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: 'grey', textDecoration: 'none' }}
          >
            Eddy kenzo.
          </a>{' '}
          Ever since we have over 2.6 billion cumulative views from{' '}
          <a
            href='https://www.youtube.com/c/TripletsGhettoKids'
            target='_blank'
            rel='noopener noreferrer'
            title='watch and subscribe'
            style={{ color: 'grey', textDecoration: 'none' }}
          >
            YouTube
          </a>
          . We went on further to win several awards including but not least
          AFRIMMA(Best African Dance 2017), BEFFTA(Best Dance Crew 2016), HIPIPO
          Awards(Trailblazer 2015), PAFA(Best Fashion Group 2017), AEA -
          USA(Best Dance Group 2017), RIAA Awards USA(2017), YouTube Creators
          Awards(2020)
        </p>
        <p>
          Triplets Ghetto Kids has been previlleged to work with several
          international acts like French Montana, Chris brown, Swae Lee, WizKid,
          Eddy Kenzo, Runtown, Japanese Pikotaro and so many others. We have
          featured on some of the world's prestigious platforms.
        </p>
      </motion.div>
      <div className='feature-title'>
        <h1>Featured on</h1>
      </div>
      <div className='feature'>
        <img src={vibe} alt='vibe' />

        <img src={influ} alt='influ' />

        <img src={bet} alt='bet' />

        <img src={disney} alt='disney' />

        <img src={fox} alt='fox' />

        <img src={jimmy} alt='jimmy' />

        <img src={uni} alt='uni' />
        <img src={talent} alt='Britain Got Talent' />
      </div>
    </>
  )
}

export default Feature

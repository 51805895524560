import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logom from '../../assets/logo.png'
import { FaYoutube, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import { ButtonW } from './Global'

// side drawer logo visible only on large devices
const DeskLogo = styled.div`
  position: fixed;
  top: 35px;
  left: 2rem;
  width: 50px;
  height: 50px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`
// actual side drawer
const Drawer = styled.ul`
  z-index: 2;
  display: flex;
  flex-flow: column;
  @media (max-width: 767px) {
    flex-flow: column;
    background-color: #000000;

    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 0;
    margin-top: 0;
    transition: transform 0.3s ease-in-out;
  }
  @media (min-width: 768px) {
    flex-flow: column nowrap;
    background-color: #000000;
    opacity: 80%;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 320px;
    padding-top: 0;
    margin-top: 0;
    transition: transform 0.3s ease-in-out;
  }
`

const SideDrawer = ({ open, setOpen }) => {
  return (
    <Drawer open={open} onClick={() => setOpen(!open)}>
      <NavItem to='/'>
        <DeskLogo>
          <img
            src={logom}
            alt='logo'
            style={{ width: '100%', height: '100%' }}
          />
        </DeskLogo>
      </NavItem>

      <Wrap>
        <Social>
          <NavItem to='/work'>- What we do</NavItem>
          <NavItem to='/founder'>- About the founder</NavItem>
          <NavItem to='/contact'>- Contact us</NavItem>

          <hr
            style={{
              border: '0.1px solid grey',
              width: '100%',
            }}
          />
          <div
            style={{
              color: '#fff',
              padding: '0 0.4rem',
              lineHeight: '20px',
            }}
          >
            <br />
            <br />
            <p>
              The Foundation Runs Entirely On Sponsorships And Donations From
              Generous Beings Just Like You.
            </p>

            <br />
            <br />
          </div>
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <AddressItem to='/donate'>
              <ButtonW>Donate</ButtonW>
            </AddressItem>

            <AddressItem to='/volunteer'>
              <ButtonW>Volunteer</ButtonW>
            </AddressItem>
          </div>

          <div style={{ display: 'flex' }}>
            <NavLink
              href='https://www.instagram.com/ghettokids_tfug'
              target='_blank'
              rel='noopener noreferrer'
              title='Follow us'
            >
              <AiFillInstagram />
            </NavLink>
            <NavLink
              href='https://www.youtube.com/c/TripletsGhettoKids'
              target='_blank'
              rel='noopener noreferrer'
              title='Subscribe'
            >
              <FaYoutube />
            </NavLink>
            <NavLink
              href='https://www.facebook.com/TripletsGhettoKidz'
              target='_blank'
              rel='noopener noreferrer'
              title='Like'
            >
              <FaFacebookF />
            </NavLink>

            <NavLink
              href='https://twitter.com/ghettokidstfug'
              target='_blank'
              rel='noopener noreferrer'
              title='Like'
            >
              <FaTwitter />
            </NavLink>
          </div>
        </Social>
      </Wrap>
    </Drawer>
  )
}

export default SideDrawer

// side drawer items as Link

const NavItem = styled(Link)`
  cursor: pointer;
  font-size: 23px;
  color: #fff;
  padding: 0.5rem 0;
  text-decoration: none;
  transition: 1s ease;
  &:hover {
    color: teal;
    text-decoration: none;
    transform: translateX(2px);
  }
`

// side drawer items as anchor tags(href)
export const NavLink = styled.a`
  padding: 0.5rem;
  font-size: 1.3rem;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: teal;
    text-decoration: none;
  }
`
// All side drawer items wrapped inside
const Wrap = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  margin: 0 1rem;
`
// social media container + physical address for offices
const Social = styled.div`
  // margin-top: 70px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 550px) {
    margin-top: 50px;
    padding-left: 2rem;
  }
`

// Button Link
const AddressItem = styled(Link)`
  cursor: pointer;
`

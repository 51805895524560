import './mission.css'
import { motion } from 'framer-motion'

function Mission() {
  return (
    <motion.div
      initial={{ scale: 0.65 }}
      whileInView={{ scale: 0.99 }}
      transition={{ ease: 'easeOut', duration: 1 }}
      className='mission-main'
    >
      <div className='mission-title'>
        <h1>Ghetto Kids</h1>
        <p>
          Inspire Ghetto Kids Foundation (Triplets Ghetto Kids) is a non-profit
          organization based in Uganda kampala founded in 2007 by Kavuma Dauda.
        </p>
      </div>
      <div className='mission-info'>
        <h1>Our Mission</h1>
        <p>
          To empower children exploit their talents through music, dance and
          drama.
        </p>
        <h1>Our Vision</h1>
        <p>To produce responsible and reliable citizens of tomorrow.</p>
      </div>
    </motion.div>
  )
}

export default Mission

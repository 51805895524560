import { FaYoutube, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import { motion } from 'framer-motion'
import './hero.css'
import { NavLink } from 'react-router-dom'

function Hero() {
  return (
    <div className='hero-main'>
      <div className='hero-info'>
        <motion.div
          initial={{ scale: 0.65 }}
          whileInView={{ scale: 0.99 }}
          transition={{ ease: 'easeOut', duration: 1 }}
        >
          <h1>Ghetto Kids</h1>
          <p>
            - Making Lives <span>Better</span> Through Dance -
          </p>
          <p className='social'>
            <a
              href='https://www.youtube.com/c/TripletsGhettoKids'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaYoutube />
            </a>

            <a
              href='https://www.instagram.com/ghettokids_tfug'
              target='_blank'
              rel='noopener noreferrer'
            >
              <AiFillInstagram />
            </a>
            <a
              href='https://www.facebook.com/TripletsGhettoKidz'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaFacebookF />
            </a>

            <a
              href='https://twitter.com/ghettokidstfug'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaTwitter />
            </a>
          </p>
          <NavLink to='/donate'>
            <button>Donate</button>
          </NavLink>
          &nbsp;&nbsp;
          <NavLink to='/volunteer'>
            <button>Volunteer</button>
          </NavLink>
        </motion.div>
      </div>
    </div>
  )
}

export default Hero

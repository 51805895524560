import { Helmet } from 'react-helmet'
import './contact.css'
import '../Volunteer/volunteer.css'

function Contact() {
  return (
    <>
      <Helmet>
        <title>Inspire Ghetto Kids Foundation | Contact</title>
      </Helmet>
      <div className='volunteer-hero'></div>

      <div className='contact-main'>
        <div className='contact-address'>
          <div>
            <h3>Physical Address:</h3>
            <p>Makindye Division - Kampala</p>
            <p>Salama rd - Kalani Zone</p>
            <p> P O box 21347, kampala - Uganda</p>
            <br />
            <h3>Office Lines:</h3>
            <p>
              <span>&#9742;&nbsp;</span>+256 771 850 668
            </p>
            <p>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>+256 704 877 551
            </p>
            <br />
            <h3>Email:</h3>
            <p>
              <span>&#9993;&nbsp;</span>
              <a href='mailto:info@inspireghettokids.org'>
                info@inspireghettokids.org
              </a>
            </p>
          </div>
        </div>
        <div className='contact-form'>
          <div>
            <form
              name='TGK'
              target='_self'
              action='https://formsubmit.co/b01283434113ba800f509bccfc2c652f'
              method='POST'
              id='formTGK'
            >
              <input
                type='hidden'
                name='_next'
                value='https://www.inspireghettokids.org/thankyou'
              />
              <input type='hidden' name='_captcha' value='false' />

              <label htmlFor='username'>
                <p>Name:</p>
              </label>
              <input
                type='text'
                id='username'
                name='username'
                required
                placeholder='Your name ...'
              />
              <br />
              <br />
              <label htmlFor='email'>
                <p>Email:</p>
              </label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder='Your email ...'
                required
                pattern='[a-z0-9]+@[a-z]+\.[a-z]{2,3}'
              />
              <br />
              <br />
              <label htmlFor='message' className='form-label'>
                <p>Message:</p>
              </label>
              <textarea
                type='text'
                id='message'
                name='message'
                placeholder='Your message ....'
                required
              ></textarea>
              <br />
              <br />
              <p>
                <button type='submit'>Send</button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
